import { useContext } from "react";
import { AltaContext } from "../../../../context";

export const useOptions = () => {
  const { comboOptions } = useContext(AltaContext);

  const categoriaOptions = comboOptions.categorias.map((category) => ({
    label: category,
    value: category,
  }));
  const agrupadoresOptions = comboOptions.agrupadores.map((agrupador) => ({
    label: agrupador.cdagrupa + ' - ' + agrupador.nombre,
    value: agrupador.cdagrupa,
  }));
  const ocupacionOptions = comboOptions?.ocupaciones?.map((ocupacion) => ({
    label: ocupacion,
    value: ocupacion,
  }));
  const ocupationDefaultOptions = [{
    label: "ADM",
    value: "ADM",
  },
  {
    label: "OPE",
    value: "OPE",
  }
]
  return {
    agrupadoresOptions,
    categoriaOptions,
    ocupacionOptions,
    ocupationDefaultOptions,
  };
};
