import { Fragment } from "react";
import { InputLabel, TextField } from "@material-ui/core";
import useStyles from "../styles";

export const Input = ({
  label,
  name,
  onChange,
  required,
  maxLenght,
  defaultValue,
  onlyLetters,
  email,
  setEmailValid
}) => {
  const classes = useStyles();

  

  return (
    <Fragment>
      <InputLabel>{label + (required ? " (*)" : "")}</InputLabel>
      <TextField
        className={classes.input}
        variant="outlined"
        name={name}
        size="small"
        onChange={onChange}
        fullWidth
        value={defaultValue ?? ""}
        inputProps={{
          ...(maxLenght ? { maxLength: maxLenght } : {}),
          style: { textTransform: "uppercase" },
        }}
        onInput={(e) => {
          if (onlyLetters) {
            e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
          }

          if (email && e.target.value.trim() !== "") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setEmailValid(emailRegex.test(e.target.value));
          } else if (email) {
            setEmailValid(false);
          }
        }}
      />
    </Fragment>
  );
};
