import { useContext, useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { AuthContext } from "../../../../../../../context/context";
import { CardName } from "../../../../../../../shared/components";
import useStyles from "./styles";
import { useOptions } from "../../hooks";
import { DatePicker, Input, Number, Select } from "../fields";
import { dateFomated } from "../../../../../hooks";
import { AltaContext } from "pages/endoso/context";

function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

function dayDiff(d1, d2, time) {
  /* validacion por debajo de 3 meses */
  if (time === 1) {
    return d1 > d2 ? true : false;
  } else return d1 !== d2 ? true : false;
}

function getAge(birthDate) {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  const monthValidation = () => {
    const month = monthDiff(birthDate, today);
    if (month < 3) return true;
    else if (month <= 3 && dayDiff(birthDate.getDate(), today.getDate(), 1))
      return true;
    return false;
  };

  const oldMonthValidation = () => {
    const month = monthDiff(birthDate, today);
    if (month > 828) return true;
    else if (dayDiff(birthDate.getDate(), today.getDate(), 2)) return true;
    return false;
  };

  return {
    age: age,
    month: monthDiff(birthDate, today),
    underMonth: monthValidation(),
    upToMonth: oldMonthValidation(),
  };
}

export const HighRiskForm = ({ setMessage, setShowMessage }) => {
  const { setTimer, persons, setPersons, showMonthlySalary } =
    useContext(AuthContext);
  const { initialData } = useContext(AltaContext);
  const classes = useStyles();
  const { agrupadoresOptions, categoriaOptions, ocupacionOptions, ocupationDefaultOptions } = useOptions();

  const generos = [
    { value: "HOMBRE", label: "Hombre" },
    { value: "MUJER", label: "Mujer" },
  ];

  const [person, setPerson] = useState({ typper: 1, sueldo: "" });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(false);

  /* methods */
  const handleChange = ({ target: { name, value } }) => {
    setTimer(0);
    if (typeof value === "string")
      setPerson({ ...person, [name]: value?.toUpperCase() });
    else setPerson({ ...person, [name]: value });
  };

  const handleSubmitData = () => {
    setTimer(0);

    const years = getAge(person.fchNacimineto);
    if (
      years.age > 69 ||
      (years.age === 69 && years.upToMonth) ||
      (years.age === 0 && years.underMonth)
    ) {
      setMessage({
        text: "Verifica la fecha. Edad permitida: Mayor de 3 meses y Menor de 69 años.",
        severity: "warning",
      });
      setShowMessage(true);
    } else {
      const fullName = `${person.primerNombre} ${person.segundoNombre ?? ""} ${person.apPaterno
        } ${person.apMaterno ?? ""}`;
      setPersons([
        ...(Array.isArray(persons) ? persons : []),
        {
          ...person,
          fchNacimineto: dateFomated(person.fchNacimineto),
          nombre: fullName,
          antiguedad: dateFomated(new Date()),
        },
      ]);
      setPerson({ typper: 1, sueldo: showMonthlySalary ? 0 : "" });
    }
  };

  /* validator */
  useEffect(() => {
    if (
      person.apPaterno &&
      person.primerNombre &&
      person.categoria &&
      person.fchNacimineto &&
      person.sexo &&
      person.agrupador &&
      (initialData?.formaAseguramiento !== null ? person.ocupacion : true) &&
      (initialData?.formaAseguramiento === "002" ? (isEmailValid && person.correo) : true) &&
      (showMonthlySalary ? person.sueldo === 0 : person.sueldo)
    ) {
      setIsDisabled(false);
    } else setIsDisabled(true);
  }, [person, showMonthlySalary, initialData, isEmailValid]);

  useEffect(() => {
    setPerson((prevPerson) => ({
      ...prevPerson,
      sueldo: showMonthlySalary ? 0 : "",
    }));
  }, [showMonthlySalary]);

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h6" color="initial">
          Lista de Asegurados
        </Typography>

        <div className={classes.form}>
          {Array.isArray(persons) &&
            persons.map((per, index) => <CardName data={per} key={index} />)}
        </div>
      </div>

      <div>
        <Typography variant="h6" color="initial">
          Datos generales
        </Typography>
        <div className={classes.form}>
          <div>
            <Input
              label="Primer Nombre"
              required
              name={"primerNombre"}
              defaultValue={person.primerNombre || ''}
              onChange={handleChange}
              maxLength={20}
              onlyLetters
            />
          </div>

          <div>
            <Input
              label="Segundo Nombre"
              name={"segundoNombre"}
              defaultValue={person.segundoNombre || ''}
              onChange={handleChange}
              maxLength={20}
              onlyLetters
            />
          </div>

          <div>
            <Input
              label="Apellido Paterno"
              required
              name={"apPaterno"}
              defaultValue={person.apPaterno || ''}
              onChange={handleChange}
              maxLength={20}
              onlyLetters
            />
          </div>

          <div>
            <Input
              label="Apellido Materno"
              name={"apMaterno"}
              defaultValue={person.apMaterno || ''}
              onChange={handleChange}
              maxLength={20}
              onlyLetters
            />
          </div>

          <div>
            <Select
              label="Género"
              name="sexo"
              required
              optionsProps={{ options: generos }}
              defaultValue={person.sexo || ""}
              onChange={handleChange}
            />
          </div>

          <div>
            <DatePicker
              label="Fecha de Nacimiento"
              required
              name="fchNacimineto"
              onChange={handleChange}
              value={person.fchNacimineto}
            />
          </div>

          <div>
            <Select
              label="Agrupador"
              name="agrupador"
              required
              optionsProps={{ options: agrupadoresOptions }}
              defaultValue={person.agrupador || ""}
              onChange={handleChange}
            />
          </div>

          <div>
            <Select
              label="Categoría"
              name="categoria"
              required
              optionsProps={{ options: categoriaOptions }}
              defaultValue={person.categoria || ""}
              onChange={handleChange}
            />
          </div>

          {!showMonthlySalary && (
            <div>
              <Number
                label="Sueldo Mensual"
                required
                name={"sueldo"}
                defaultValue={person.sueldo}
                onChange={handleChange}
                maxLength={20}
              />
            </div>
          )}

          {initialData?.formaAseguramiento !== null && (
            <div>
              <Select
                label="Ocupación"
                required
                name={"ocupacion"}
                defaultValue={person.ocupacion || ''}
                onChange={handleChange}
                optionsProps={{ options: ocupacionOptions ?? ocupationDefaultOptions }}
              />
            </div>
          )}
          {initialData?.formaAseguramiento === "002" && (
            <div>
              <Input
                label="Correo Electrónico"
                required
                name={"correo"}
                defaultValue={person.correo}
                onChange={handleChange}
                maxLength={40}
                email
                setEmailValid={setIsEmailValid}
              />
            </div>
          )}
        </div>
      </div>

      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isDisabled}
          onClick={handleSubmitData}
        >
          Agregar Persona
        </Button>
      </div>
    </div>
  );
};

