import { Typography } from "@material-ui/core";
import { Fragment, useContext } from "react";
import { asyncForEach } from "..";
import { endoso } from "../../../../services";
import { AltaContext, EndosoContext } from "../../context";
import { AuthContext } from "context/context";

const ErrorComponent = ({ errors, isFromBack = false }) => {
  return (
    <Fragment>
      <Typography>
        Las siguientes personas no pudieron ser agregadas:
      </Typography>
      {errors.map((person, index) => (
        <div key={index}>
          {isFromBack ? (
            <Typography>{`${person.nombre}: ${person?.mensaje}`}</Typography>
          ) : (
            <>
              {person.segundoNombre ? (
                <Typography>{`${person.primerNombre ?? ''} ${person.segundoNombre ?? ''} ${person.apPaterno ?? ''
                  } ${person.apMaterno ?? ''}: ${person?.error?.replace(
                    /null\s/g,
                    ""
                  )}`}</Typography>
              ) : (
                <Typography>{`${person.primerNombre ?? ''} ${person.apPaterno ?? ''} ${person.apMaterno ?? ""
                  }: ${person?.error?.replace(/null\s/g, "")}`}</Typography>
              )}
            </>
          )}

          <br />
        </div>
      ))}
    </Fragment>
  );
};

export const useRulesBy14 = () => {
  const { createMessage, formDta, showButtons, setShowButtons } =
    useContext(EndosoContext);
  const { setShowContinue } = useContext(AuthContext);
  const { setBadPersons, initialData } = useContext(AltaContext);
  let alertShow = false;
  const rulesByFourteen = async (data, formDta, persons, setPersons) => {
    if (!Array.isArray(persons)) {
      /* if add persons with a file */
      const extension = persons.name.split(".").pop();
      const permitedExtensions = ["xlsx"];
      if (permitedExtensions.includes(extension)) {
        const uploadResponse = await endoso.uploadFile(
          persons,
          data.oficina,
          data.ramo,
          data.poliza,
          formDta.noSolicitud,
          initialData.formaAseguramiento,
        );

        if (uploadResponse.data?.codigo === "1") {
          if (!!uploadResponse.data?.errores.length) {
            const errorMessage = uploadResponse.data?.errores[0]?.mensaje ?? "";
            createMessage({
              text: `No se pudieron agregar todas las personas, por favor verifique que los datos sean correctos: ${errorMessage}`,
              variant: "warning",
            });
          }
          if (!!uploadResponse.data?.altas902.length) {
            const altas902 = uploadResponse.data?.altas902;

            await savePersons(
              data,
              altas902,
              setPersons,
              uploadResponse.data?.errores ?? []
            );
          }
        } else
          createMessage({
            text:
              uploadResponse.data?.mensaje ??
              "Ocurrio un error con su solicitud",
            variant: "warning",
          });
      } else {
        createMessage({
          variant: "warning",
          text: "El archivo no tiene una extensión permitida, por favor verifique.",
        });
      }
    } else {
      await savePersons(data, persons, setPersons);
    }
  };

  async function savePersons(data, persons, setPersons, fileErrors) {
    if (persons.length === 0) {
      createMessage({
        text: "No hay personas para procesar",
        variant: "warning",
      });
    } else {
      const removedPersons = [];
      const personsCopy = [...persons];
      const altasFunction = async (person, index) => {
        delete person.typper;
        const body = {
          ...person,
          solicitud: formDta.noSolicitud,
          ramo: data.ramo,
          poliza: data.poliza,
          oficina: data.oficina,
          razonSocial: " ",
          rfc: null,
          tipoIdentificador: "0",
        };
        const savePerson = await endoso.alta902(body);
        if (savePerson.data?.alerta && savePerson.data?.codigo === 1 && !alertShow) {
          createMessage({
            text: savePerson?.data?.alerta,
            variant: "info",
            action: true,
          });
          alertShow = true;
          setShowContinue(true);
        };
        if (savePerson.data?.codigo === 1) {
          const situacionResponse = await endoso.guardarSituacion({
            situacion: savePerson.data?.mensaje,
            solicitud: formDta.noSolicitud,
          });
          if (situacionResponse.data?.codigo === 1) {
            return 1;
          } else {
            removedPersons.push({
              ...persons[index],
              pos: index,
              error: situacionResponse.data?.mensaje,
            });
            return 0;
          }
        } else {
          removedPersons.push({
            ...persons[index],
            pos: index,
            error: savePerson.data?.mensaje,
          });
          return 0;
        }
      };

      /* if the file have errors */
      const getPersonsWithErrors = (notSavedPersons) => {
        if (fileErrors?.length) {
          const mapErrors = fileErrors.map((error) => ({
            nombre: error.nombre,
            error: error.mensaje,
          }));
          return [...(notSavedPersons ?? []), ...(mapErrors ?? [])];
        }
        return notSavedPersons;
      };
      /* ----------- */
      const responseSaves = await asyncForEach(personsCopy, altasFunction);

      const filteredPersons = persons.filter(
        (person, index) => !removedPersons.find((item) => item?.pos === index)
      );
      setPersons(filteredPersons);

      if (responseSaves.find((save) => save === 1)) {
        const diferenciaResponse = await endoso.diferencia({
          solicitud: formDta.noSolicitud,
        });
        if (removedPersons.length > 0) {
          createMessage({
            text: <ErrorComponent errors={removedPersons} />,
            variant: "warning",
          });
        }
        if (diferenciaResponse.data?.codigo === "1") {
          const filteredPersons = persons.filter(
            (person, index) =>
              !removedPersons.find((item) => item?.pos === index)
          );
          setPersons(
            filteredPersons.map((person) => ({
              ...person,
              importeActual: diferenciaResponse?.data.importeActual,
              importeAnterior: diferenciaResponse?.data.importeAnterior,
              importeDiferencia: diferenciaResponse?.data.importeDiferencia,
            }))
          );
          const clausulaResponse = await endoso.clausula({
            solicitud: formDta.noSolicitud,
          });
          if (clausulaResponse.data?.codigo === "1") {
            setBadPersons(getPersonsWithErrors(removedPersons));
            setShowButtons({ ...showButtons, multiTable: true });
          } else {
            createMessage({
              text:
                clausulaResponse.data?.mensaje ||
                "Ocurrio un error de conexion",
              variant: "warning",
            });
          }
        } else {
          createMessage({
            text:
              diferenciaResponse.data?.mensaje ||
              "Ocurrio un error de conexion",
            variant: "warning",
          });
        }
      } else {
        createMessage({
          text: <ErrorComponent errors={removedPersons} />,
          variant: "warning",
        });
      }
    }
  }

  return { rulesByFourteen };
};
