import { format } from "date-fns";

export const generarInfoTabla = (dataOriginal, dataActual, type, formDta) => {
  if (type === 1 || type === 2 || type === 3 || type === 6) {
    return [
      {
        id: 1,
        campo: "RFC",
        valorOriginal: dataOriginal.RFC,
        valorNuevo: dataActual.RFC,
      },
      {
        id: 2,
        campo: "Primer Nombre",
        valorOriginal: dataOriginal.NOMBR1,
        valorNuevo: dataActual.NOMBR1,
      },
      {
        id: 3,
        campo: "Segundo Nombre",
        valorOriginal: dataOriginal.NOMBR2 || "NO DISPONIBLE",
        valorNuevo: dataActual.NOMBR2 || "NO DISPONIBLE",
      },
      {
        id: 4,
        campo: "Apellido Paterno",
        valorOriginal: dataOriginal.DSAPEL1,
        valorNuevo: dataActual.DSAPEL1,
      },
      {
        id: 5,
        campo: "Apellido Materno",
        valorOriginal: dataOriginal.DSAPEL2,
        valorNuevo: dataActual.DSAPEL2,
      },
      {
        id: 6,
        campo: "Fecha de Nacimiento",
        valorOriginal: dataOriginal.FECNAC,
        valorNuevo:
          typeof dataActual.FECNAC === "string"
            ? dataActual.FECNAC
            : dateToString(dataActual.FECNAC),
      },
      {
        id: 7,
        campo: "Sexo",
        valorOriginal:
          dataOriginal.TIPPER === "2"
            ? "EMPRESA"
            : dataOriginal.SEXO === "M"
              ? "MUJER"
              : "HOMBRE",
        valorNuevo:
          dataActual.TIPPER === "2"
            ? "EMPRESA"
            : dataActual.SEXO === "M"
              ? "MUJER"
              : "HOMBRE",
      },
      {
        id: 8,
        campo: "Tipo de Persona",
        valorOriginal: dataOriginal.TIPPER === "1" ? "FISICA" : "MORAL",
        valorNuevo: dataActual.TIPPER === "1" ? "FISICA" : "MORAL",
      },
    ];
  }
  if (type === 9 || type === 10 || type === 11 || type === 18 || type === 19) {
    const values = {
      9: {
        id: 1,
        campo: "Modificación de número de serie",
        valorOriginal: dataOriginal.SERIE,
        valorNuevo: dataActual.SERIE,
      },
      10: {
        id: 1,
        campo: "Modificación de número de placas",
        valorOriginal: dataOriginal.PLACAS,
        valorNuevo: dataActual.PLACAS,
      },
      11: {
        id: 1,
        campo: "Modificación de número de motor",
        valorOriginal: dataOriginal.MOTOR,
        valorNuevo: dataActual.MOTOR,
      },
      18: {
        id: 1,
        campo: "Modificación de Descripción",
        valorOriginal: dataOriginal,
        valorNuevo: dataActual,
      },
      19: {
        id: 1,
        campo: "Modificación de Modelo",
        valorOriginal: dataOriginal.MODELO,
        valorNuevo: dataActual.MODELO,
      },
    };
    return [values[type]];
  }
  if (type === 5 || type === 7) {
    const getDescription = (id) =>
      formDta.datosEndoso?.[0]?.catalogo.catalogo.find(
        (item) => item.id === id?.toString()
      )?.descripcion;

    const values = {
      5: {
        id: 1,
        campo: "Cancelacion",
        valorOriginal: getDescription(dataOriginal.MOTANU) || "NO DISPONIBLE",
        valorNuevo: getDescription(dataActual.MOTANU) || "NO DISPONIBLE",
      },
      7: {
        id: 1,
        campo: "Cambio de forma de pago",
        valorOriginal: getDescription(dataOriginal.PERPAG) || "NO DISPONIBLE",
        valorNuevo: getDescription(dataActual.PERPAG) || "NO DISPONIBLE",
      },
    };
    return [values[type]];
  }
  if (type === 8) {
    return [
      {
        id: 1,
        campo: "Alta de Cobertura",
        valorOriginal: dataOriginal,
        valorNuevo: dataActual,
      },
    ];
  }
};
export const obtenerIndiceCambio = function (viejo, nuevo) {
  let s = 0;
  while (s < viejo?.length && s < nuevo?.length && viejo[s] === nuevo[s]) {
    s++;
  }

  if (viejo?.length === nuevo?.length && s === viejo?.length) {
    s = -1;
  }
  return s;
};

export const regexIndexOf = function (text, regex, fromIndex) {
  const str = fromIndex ? text.substring(fromIndex) : text;
  const match = str?.match(regex);
  return match ? str?.indexOf(match[0]) + fromIndex : -1;
};
export const formatDate = (date) => {
  const parts = date.split("/");
  return `${parts[1]}-${parts[0]}-${parts[2]}`;
};
export const buscarVocal = function (texto) {
  const textoTmp = texto?.toLowerCase();
  const i = regexIndexOf(textoTmp, /[aeiou]/, 0);
  if (i === 0) {
    // se debe buscar siguiente vocal
    return regexIndexOf(textoTmp, /[aeiou]/, 1);
  } else {
    return i;
  }
};
export const validarCambioNombre = function (viejo, nuevo, posicion) {
  const i = obtenerIndiceCambio(viejo, nuevo);
  if (i < 0) {
    return true;
  }
  if (posicion >= 0) {
    if (posicion >= i) {
      return false;
    }
  } else {
    if (buscarVocal(viejo) >= i) {
      return false;
    }
  }
  return true;
};

export const contarDiferencias = function (viejo, nuevo) {
  // eslint-disable-next-line
  let viejoTmp = new String(viejo); // eslint-disable-next-line
  let nuevoTmp = new String(nuevo);

  let contador = 0;
  let s = 0;
  while (viejoTmp.length > 0 || nuevoTmp.length > 0) {
    s = obtenerIndiceCambio(viejoTmp, nuevoTmp);
    if (s > -1) {
      viejoTmp = viejoTmp.substring(s + 1, viejoTmp.length);
      nuevoTmp = nuevoTmp.substring(s + 1, nuevoTmp.length);
      contador++;
    } else {
      break;
    }
  }
  return contador;
};

export const dateToString = (date) => {
  const fecha = new Date(date);
  const year = fecha.getFullYear();
  const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const day = fecha.getDate().toString().padStart(2, "0");
  return `${day}/${month}/${year}`;
};

export const getTypePerson = (inputs) => {
  let typePerson;
  if (!inputs.find((input) => input.acronimo === "TIPPER")?.catalogo) {
    typePerson = inputs.find(
      (input) => input.acronimo === "TIPPER"
    )?.descripcionAnterior;
  } else
    typePerson =
      inputs.find((input) => input.acronimo === "TIPPER")?.input.valorActual ??
      "";
  return typePerson;
};

export async function asyncForEach(array, callback) {
  const responses = [];
  for (let index = 0; index < array.length; index++) {
    const response = await callback(array[index], index);
    responses.push(response);
  }
  return responses;
}

function GetDate(value) {
  if (!value) return null;

  const date = value instanceof Date ? value : new Date(value);

  return isNaN(date.getTime()) ? null : date;
}

export function dateFomated(value) {
  const date = GetDate(value);
  return date ? format(date, "dd/MM/yyyy") : "";
}

export function formatSex(value) {
  switch (value) {
    case "M":
      return "MUJER";

    case "H":
      return "HOMBRE";
    default:
      return "";
  }
}
export function formatPerentesco(value) {
  switch (value) {
    case "T":
      return "TITULAR";
    case "C":
      return "CONYUGUE";
    case "H":
      return "HIJO";
    case "O":
      return "OTRO";
    default:
      return "";
  }
}

export function toUppercase(data) {
  if (typeof data === "string") return data.toUpperCase();
  return data;
}
